











































































































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { Patient } from "../../types/patient.type";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { PatientTreatment } from "../../types/patient-treatment.type";
import { Payment } from "../../types/payment.type";
import { Treatment } from "@/modules/treatment/types/treatment.type";
import { date, time } from "@/filters/date.filter";
import { DataTableHeader } from "vuetify";
import PatientLetterApi from "../../api/patient-letter.api";
import { Getter } from "vuex-class";
import { enableDatePickerAdjacentMonths } from "@/modules/appointment/helpers/date-picker-helpers";
import moment from "moment";
import PatientFileExplorerDialog from "@/modules/patient-file/components/patient-file-explorer/PatientFileExplorerDialog.vue";
import EmailContactSearch from "@/modules/contacts/components/EmailContactSearch.vue";


@Component({
  components: {
    PatientFileExplorerDialog,
    EmailContactSearch
  },
  filters: {
    date,
    time,
  }
})
export default class PatientExportDialog extends mixins(SmileMixin) {
  @Prop({ required: true }) public patient: Patient;
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;
  @Getter("auth/isEmailEnabled") isEmailEnabled: boolean;
  @Getter("auth/isWhatsappBotEnabled") isWhatsappBotEnabled: boolean;

  public phone = "";
  public email = "";
  public emailCc = "";
  public isMarkedSendWaToPatient = false;
  public isMarkedSendEmailToPatient = false;
  public isMarkedSendEmailCcPatient = false;
  public showErrors = false;
  public errors: any = false;
  public selectedPatientFileIds: number[] = [];
  public isSendLoading = false;
  public isExportIncludePayments = true;
  public patientBalance: number = null;
  public treatmentsAndPayments: (Payment | PatientTreatment)[] = [];
  public hideExportDatePicker = false;
  public isDisplayPatientFileExplorerDialog = false;

  get selectedPatientFiles() {
    return this.patient.files.filter(({ id }) => (this.selectedPatientFileIds.includes(id)));
  }

  public mounted() {
    this.calculateDataForExport();
    if (this.patient.email) {
      this.email = this.patient.email;
    }
    if (this.patient.phone) {
      this.phone = this.patient.phone;
    }
    if (!this.isEditEnabled) {
      this.isExportIncludePayments = false;
    }
  }
  public onCreateLetterClicked(patientFileIds: number[]) {
    this.selectedPatientFileIds = [...patientFileIds];
    this.isDisplayPatientFileExplorerDialog = false;
  }

  public onOpenPatientFileExplorerClicked() {
    this.isDisplayPatientFileExplorerDialog = true;
  }

  public removeFileId(fileId: number) {
    const index = this.selectedPatientFileIds.indexOf(fileId);
    this.selectedPatientFileIds.splice(index, 1);
  }

  get treatmentPaymentId() {
    let paymentTreatment = this.$store.getters["auth/treatments"]
      .find((treatment: Treatment) => (treatment.name === "תשלום" && !treatment.deleted_at));

    if (!paymentTreatment) {
      paymentTreatment = this.$store.getters["auth/treatments"]
        .find((treatment: Treatment) => (treatment.name === "תשלום"));
    }
    return paymentTreatment.id;
  }

  public abs(number: number) {
    return Math.abs(number);
  }

  @Watch("patient")
  public calculateDataForExport() {
    const treatmentsAndPayments = JSON.parse(JSON.stringify([
      ...this.patient.payments,
      ...this.patient.treatments,
    ])).sort((a, b) => {
      if (a.created_at < b.created_at) return 1;
      if (a.created_at > b.created_at) return -1;
      return 0;
    });

    let balance = 0;
    for (let i = treatmentsAndPayments.length - 1; i >= 0; i--) {
      if ("treatment_id" in treatmentsAndPayments[i]) {
        balance += (treatmentsAndPayments[i] as PatientTreatment).price - (treatmentsAndPayments[i] as PatientTreatment).discount;
        (treatmentsAndPayments[i] as PatientTreatment).notes = (this.treatmentName((treatmentsAndPayments[i] as PatientTreatment).treatment_id) + "\n" + (treatmentsAndPayments[i] as PatientTreatment).notes).trim();
      } else {
        (treatmentsAndPayments[i] as any).notes = (this.$t("payment_received") + " " + this.getPaymentSummary(treatmentsAndPayments[i])).trim();
        if (!(treatmentsAndPayments[i] as Payment).deleted_at) {
          balance -= (treatmentsAndPayments[i] as Payment).amount;
        }
      }

      (treatmentsAndPayments[i] as any).isHidden = false;
      (treatmentsAndPayments[i] as any).balance = balance;
    }

    this.patientBalance = balance;
    this.treatmentsAndPayments = treatmentsAndPayments;
  }

  public async hideItem(item: Payment | PatientTreatment) {

    const res = await this.$confirm(
      this.$t("confirm_hide_item").toString(),
      {
        buttonTrueColor: "red",
        buttonTrueText: this.$t("hide_item_ok_btn").toString(),
        buttonFalseText: this.$t("hide_item_cancel_btn").toString(),
      });

    if (!res) {
      return;
    }

    (item as any).isHidden = true;
  }

  get displayTreatmentsAndPayments() {
    return this.treatmentsAndPayments
      .filter(item => (this.isExportIncludePayments || ("treatment_id" in item && item.treatment_id !== this.treatmentPaymentId)))
      .filter(item => (!(item as any).isHidden));
  }

  get headers() {
    const headers: DataTableHeader[] = [
      { text: this.$t("headers.date").toString(), value: "date", sortable: false, width: 100 },
      { text: this.$t("headers.user_id").toString(), value: "user_id", sortable: false, width: 100 },
      { text: this.$t("headers.description").toString(), value: "description", sortable: false },
    ];

    if (this.isExportIncludePayments) {
      headers.push({ text: this.$t("headers.price").toString(), value: "price", sortable: false, width: 100 });
      headers.push({ text: this.$t("headers.balance").toString(), value: "balance", sortable: false, width: 100 });
    }

    headers.push({ text: "", value: "action", sortable: false, width: 20 });

    return headers;
  }

  public async closeDialog(closeWithoutConfirm: boolean) {
    if (!closeWithoutConfirm) {
      const res = await this.$confirm(
        this.$t("confirm_close_letter").toString(),
        {
          buttonTrueColor: "cyan darken-2",
          buttonTrueText: this.$t("close_letter_ok_btn").toString(),
          buttonFalseText: this.$t("close_letter_cancel_btn").toString(),
        });

      if (!res) {
        return;
      }
    }
    this.$emit("close");
  }

  public getPaymentSummary(payment: Payment) {
    let summary = "";
    if (payment.attributes.cash) {
      summary += this.$t("cash") + ": " + payment.attributes.cash.toLocaleString() + "₪ ";
    }
    if (payment.attributes.check) {
      summary += this.$t("check") + ": " + payment.attributes.check.toLocaleString() + "₪ ";
    }
    if (payment.attributes.bank_transfer) {
      summary += this.$t("bank_transfer") + ": " + payment.attributes.bank_transfer.toLocaleString() + "₪ ";
    }
    if (payment.attributes.credit_card) {
      summary += this.$t("credit_card") + ": " + payment.attributes.credit_card.toLocaleString() + "₪ ";
    }
    return summary;
  }

  public async submitForm() {
    const isSendingLetterWarn = (this.isEmailEnabled || this.isWhatsappBotEnabled) && !this.isMarkedSendWaToPatient && !this.isMarkedSendEmailToPatient && !this.isMarkedSendEmailCcPatient;
    const res = await this.$confirm(
      this.$t(isSendingLetterWarn ? "create_letter_confirm_message" : ((this.isEmailEnabled || this.isWhatsappBotEnabled) ? "send_letter_confirm_message" : "create_letter_file_confirm_message")).toString(),
      {
        color: isSendingLetterWarn ? "pink lighten-3" : undefined,
        icon: "",
        title: isSendingLetterWarn ? this.$t("create_letter_confirm_title").toString() : undefined,
        buttonTrueColor: "cyan darken-2",
        buttonTrueText: this.$t("send_letter_ok_btn").toString(),
        buttonFalseText: this.$t("send_letter_cancel_btn").toString(),
      });

    if (!res) {
      return;
    }

    this.errors = false;
    this.showErrors = true;

    try {
      this.isSendLoading = true;
      const exportData = [];
      for (let i = 0; i < this.displayTreatmentsAndPayments.length; i++) {
        exportData.push({
          created_at: this.displayTreatmentsAndPayments[i].created_at,
          deleted_at: (this.displayTreatmentsAndPayments[i] as any).deleted_at,
          notes: (this.displayTreatmentsAndPayments[i] as any).notes,
          userId: (this.displayTreatmentsAndPayments[i] as any).user_id,
          amount: (this.displayTreatmentsAndPayments[i] as any).amount,
          price: (this.displayTreatmentsAndPayments[i] as any).price,
          discount: (this.displayTreatmentsAndPayments[i] as any).discount,
          balance: (this.displayTreatmentsAndPayments[i] as any).balance,
        });
      }

      await PatientLetterApi.createExportPatient(this.patient.id, exportData, this.patientBalance, this.isExportIncludePayments, this.isMarkedSendEmailToPatient ? this.email : "", this.isMarkedSendEmailCcPatient ? this.emailCc : "", this.isMarkedSendWaToPatient ? this.phone : "", this.selectedPatientFileIds);
      this.closeDialog(true);
      this.$toastr.s(this.$t("letter_created"));
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors) {
        this.errors = err.response.data.errors;
      } else {
        this.errors = this.$t("temporary_error");
      }
    } finally {
      this.isSendLoading = false;
      this.$emit("refreshPatientFiles");
    }
  }

  public onPhoneInputBlur() {
    if (this.phone) {
      this.isMarkedSendWaToPatient = true;
    }
  }

  public onEmailInputBlur() {
    if (this.email) {
      this.isMarkedSendEmailToPatient = true;
    }
  }

  public updated() {
    this.enableAdjacentMonths();
  }

  public enableAdjacentMonths() {
    setTimeout(() => enableDatePickerAdjacentMonths(), 50);
  }

  public async hideBeforeDate(date: string) {
    const res = await this.$confirm(
      this.$t("confirm_hide_items_before_date", { date: moment(date).format("DD-MM-YYYY") }).toString(),
      {
        buttonTrueColor: "red",
        buttonTrueText: this.$t("hide_item_ok_btn").toString(),
        buttonFalseText: this.$t("hide_item_cancel_btn").toString(),
      });

    if (!res) {
      return;
    }

    this.displayTreatmentsAndPayments.forEach(item => {
      if (item.created_at < date) {
        (item as any).isHidden = true;
      }
    });
  }

  public onEmailCcInputBlur() {
    if (this.emailCc) {
      this.isMarkedSendEmailCcPatient = true;
    }
  }

  public emailCcContact(email: string) {
    this.emailCc = email;
    this.isMarkedSendEmailCcPatient = true;
  }
}
