

































































































































































































import moment from "moment";
import { Component, Prop } from "vue-property-decorator";
import PatientLetterApi from "../../api/patient-letter.api";
import { Patient } from "../../types/patient.type";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { Getter } from "vuex-class";
import SentenceMenu from "@/modules/appointment/components/appointment-documentation/SentenceMenu.vue";
import PatientFileExplorerDialog from "@/modules/patient-file/components/patient-file-explorer/PatientFileExplorerDialog.vue";
import EmailContactSearch from "@/modules/contacts/components/EmailContactSearch.vue";

@Component({
  components: {
    SentenceMenu,
    PatientFileExplorerDialog,
    EmailContactSearch
  },
})
export default class PatientCreateLetterDialog extends mixins(SmileMixin) {
  @Prop({ required: true }) public title: string;
  @Prop({ required: true }) public patient: Patient;
  @Prop({ required: false, default: "" }) public defaultTo: string;
  @Prop({ required: false, default: "" }) public defaultSubject: string;
  @Prop({ required: false, default: "" }) public defaultContent: string;
  @Prop({ required: false, default: "" }) public defaultSignnature: string;
  @Prop({ required: false, default: "" }) public filename: string;
  @Prop({ required: false }) public fileIds: number[];
  @Getter("auth/isEmailEnabled") isEmailEnabled: boolean;
  @Getter("auth/isWhatsappBotEnabled") isWhatsappBotEnabled: boolean;

  public phone = "";
  public email = "";
  public emailCc = "";
  public emailCc2 = "";
  public to = "";
  public subject = "";
  public content = "";
  public signature = "";
  public date = "";
  public isMarkedSendWaToPatient = false;
  public isMarkedSendEmailToPatient = false;
  public isMarkedSendEmailCcPatient = false;
  public isMarkedSendEmailCc2Patient = false;
  public showErrors = false;
  public errors: any = false;
  public selectedPatientFileIds: number[] = [];
  public isSendLoading = false;
  public isDisplayPatientFileExplorerDialog = false;
  public isAutoComplete = false;

  get selectedPatientFiles() {
    return this.patient.files.filter(({ id }) => (this.selectedPatientFileIds.includes(id)));
  }

  public onUpdatePatientFiles() {
    this.$emit("refreshPatientFiles");
  }

  public mounted() {
    if (this.patient.attributes && this.patient.attributes.referred_by_contact_ids && this.patient.attributes.referred_by_contact_ids.length) {
      const contactIdWithEmail = this.patient.attributes.referred_by_contact_ids.find(contactId => this.contactById(contactId).email);
      if (contactIdWithEmail) {
        this.emailCc = this.contactById(contactIdWithEmail).email;
      }
    }
    this.to = this.$props.defaultTo;
    this.subject = this.$props.defaultSubject;
    this.content = this.$props.defaultContent;
    this.signature = this.$props.defaultSignnature;
    this.email = this.$props.patient.email ? this.$props.patient.email : "";
    if (this.patient.email) {
      this.email = this.patient.email;
    }
    if (this.patient.phone) {
      this.phone = this.patient.phone;
    }
    this.date = moment().format("DD-MM-YYYY");

    if (this.fileIds && this.fileIds.length) {
      this.selectedPatientFileIds = [...this.fileIds];
    }
  }

  public onCreateLetterClicked(patientFileIds: number[]) {
    this.selectedPatientFileIds = [...patientFileIds];
    this.isDisplayPatientFileExplorerDialog = false;
  }

  public onOpenPatientFileExplorerClicked() {
    this.isDisplayPatientFileExplorerDialog = true;
  }

  public removeFileId(fileId: number) {
    const index = this.selectedPatientFileIds.indexOf(fileId);
    this.selectedPatientFileIds.splice(index, 1);
  }

  public async closeDialog(closeWithoutConfirm: boolean) {
    if (!closeWithoutConfirm && this.content.trim().length > 3) {
      const res = await this.$confirm(
        this.$t("confirm_close_letter").toString(),
        {
          buttonTrueColor: "cyan darken-2",
          buttonTrueText: this.$t("close_letter_ok_btn").toString(),
          buttonFalseText: this.$t("close_letter_cancel_btn").toString(),
        });

      if (!res)
        return;
    }
    this.$emit("close");
  }

  public appendSentence(sentence: string) {
    if (this.content) {
      this.content += " " + sentence;
    } else {
      this.content = sentence;
    }
  }

  public emailCcContact(email: string) {
    this.emailCc = email;
    this.isMarkedSendEmailCcPatient = true;
  }

  public emailCc2Contact(email: string) {
    this.emailCc2 = email;
    this.isMarkedSendEmailCc2Patient = true;
  }

  public async submitForm() {
    const isSendingLetterWarn = (this.isEmailEnabled || this.isWhatsappBotEnabled) && !this.isMarkedSendWaToPatient && !this.isMarkedSendEmailToPatient && !this.isMarkedSendEmailCcPatient && !this.isMarkedSendEmailCc2Patient;
    const res = await this.$confirm(
      this.$t(isSendingLetterWarn ? "create_letter_confirm_message" : ((this.isEmailEnabled || this.isWhatsappBotEnabled) ? "send_letter_confirm_message" : "create_letter_file_confirm_message")).toString(),
      {
        color: isSendingLetterWarn ? "pink lighten-3" : undefined,
        icon: "",
        title: isSendingLetterWarn ? this.$t("create_letter_confirm_title").toString() : undefined,
        buttonTrueColor: "cyan darken-2",
        buttonTrueText: this.$t("send_letter_ok_btn").toString(),
        buttonFalseText: this.$t("send_letter_cancel_btn").toString(),
      });

    if (!res)
      return;

    this.errors = false;
    this.showErrors = true;

    try {
      this.isSendLoading = true;
      await PatientLetterApi.create(this.patient.id, this.to, this.signature, this.subject, this.content, this.filename ? this.filename : this.subject, this.date, this.isMarkedSendEmailToPatient ? this.email : "", this.isMarkedSendEmailCcPatient ? this.emailCc : "", this.isMarkedSendEmailCc2Patient ? this.emailCc2 : "", this.isMarkedSendWaToPatient ? this.phone : "", this.selectedPatientFileIds);
      this.closeDialog(true);
      this.$toastr.s(this.$t("letter_created"));
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors) {
        this.errors = err.response.data.errors;
      } else {
        this.errors = this.$t("temporary_error");
      }
    } finally {
      this.isSendLoading = false;
      this.$emit("refreshPatientFiles");
    }
  }

  public onPhoneInputBlur() {
    if (this.phone) {
      this.isMarkedSendWaToPatient = true;
    }
  }

  public onEmailInputBlur() {
    if (this.email) {
      this.isMarkedSendEmailToPatient = true;
    }
  }

}
