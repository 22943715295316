<template>
  <VDialog @input="closeDialog()" :value="true" max-width="360">
    <v-card outlined raised elevation="4">
      <v-card-title class="justify-space-between">
        {{ $t('edit_title') }}
        <VBtn icon @click="closeDialog()">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </v-card-title>
      <v-card-text>
        <smile-error-message :errors="errors"></smile-error-message>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="name" :label="$t('name')" color="cyan darken-1" type="text" hide-details />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field name="name" v-model="order" :label="$t('order')" color="cyan darken-1" type="text"
                hide-details />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-text-field v-model="durationMinutes" :label="$t('duration_minutes')" color="cyan darken-1"
                type="text" />
            </v-col>
            <v-col cols="12">
              <VColorPicker v-model="color" :label="$t('color')" color="cyan darken-1" mode="hexa" hide-mode-switch />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <VCardActions>
        <VBtn v-if="editAppointmentType" color="red" small text :loading="deleteLoading"
          :disabled="loading || deleteLoading" type="button" @click="deleteButtonClicked()">
          {{ $t('delete_button') }}
        </VBtn>
        <VSpacer />
        <VBtn color="cyan" :loading="loading" @click="submitForm()">
          {{ $t('submit') }}
        </VBtn>
      </VCardActions>
    </v-card>
  </VDialog>
</template>

<script>
import AppointmentTypeApi from "../../../../serivce/api/appointment-type-api";

export default {
  props: ["editAppointmentType"],
  computed: {
    colorOptions() {
      return [
        ["#FFFFFF", "#FD7B7B"],
        ["#FFFF9F", "#FBAEFF"],
        ["#B8FFB8", "#FFE787"],
        ["#81FFFF", "#C2C2FF"],
      ];
    },
  },
  data() {
    return {
      errors: false,
      name: this.editAppointmentType ? this.editAppointmentType.name : "",
      durationMinutes: this.editAppointmentType ? this.editAppointmentType.duration_minutes : "",
      loading: false,
      deleteLoading: false,
      color: this.editAppointmentType ? this.editAppointmentType.color : "#FFFF9F",
      order: this.editAppointmentType ? this.editAppointmentType.order : "1",
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    async deleteButtonClicked() {
      const res = await this.$confirm(
        this.$t("delete_confirm_message").toString(),
        {
          buttonTrueColor: "red",
          buttonTrueText: this.$t("delete_ok_btn").toString(),
          buttonFalseText: this.$t("delete_cancel_btn").toString(),
        });

      if (!res) {
        return;
      }

      this.deleteLoading = true;
      AppointmentTypeApi
        .delete(this.editAppointmentType.id)
        .then(() => {
          this.$toastr.s(this.$t("deleted_toastr"));
          this.$emit("reload");
          this.closeDialog();
        })
        .catch(() => {
          this.$toastr.e(this.$t("temporary_error"));
        })
        .finally(() => this.deleteLoading = false);
    },
    submitForm() {
      if (this.loading) return;

      this.loading = true;
      this.errors = false;


      const attempt = this.editAppointmentType
        ? AppointmentTypeApi.update(this.editAppointmentType.id, this.name, this.durationMinutes, this.color, this.order)
        : AppointmentTypeApi.create(this.name, this.durationMinutes, this.color, this.order);
      attempt
        .then(() => {
          this.loading = false;

          this.$toastr.s(this.editAppointmentType ? this.$t("type_updated") : this.$t("type_created"));
          this.closeDialog();
          this.$emit("reload");
        })
        .catch(err => {
          this.loading = false;
          if (err.response && err.response.data && err.response.data.errors) {
            this.errors = err.response.data.errors;
          } else {
            this.errors = this.$t("temporary_error");
          }
        });
    }
  }
};
</script>

<i18n>
{
  "he": {
    "name": "סוג פגישה",
    "order": "סדר",
    "duration_minutes": "משך",
    "edit_title": "עריכת סוג פגישה",
    "submit": "שמירה",
    "type_created": "סוג פגישה נוצר בהצלחה",
    "type_updated": "סוג פגישה עודכן בהצלחה",
    "deleted_toastr": "סוג פגישה נמחקה",
    "delete_button": "מחיקה",
    "delete_cancel_btn": "לא",
    "delete_confirm_message": "למחוק את סוג הפגישה?",
    "delete_ok_btn": "כן. למחוק",
    "temporary_error": "שגיאה בשמירה. יש לנסות שוב"
  },
  "en": {
    "name": "Title",
    "order": "Order",
    "duration_minutes": "Duration",
    "edit_title": "Edit Appointment Type",
    "submit": "Submit",
    "type_created": "Appointment type created successfully",
    "type_updated": "Appointment type updated successfully",
    "deleted_toastr": "Appointment type deleted",
    "delete_button": "Delete",
    "delete_cancel_btn": "לא",
    "delete_cancel_btn": "Cancel",
    "delete_confirm_message": "Are you sure?",
    "delete_ok_btn": "Delete",
    "temporary_error": "Error saving data, try again"
  }
}
</i18n>
