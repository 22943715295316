







































































































































import moment from "moment";
import { Component, Prop } from "vue-property-decorator";
import PatientLetterApi from "../../api/patient-letter.api";
import { Patient } from "../../types/patient.type";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { Getter } from "vuex-class";
import { User } from "@/modules/users/types/user.type";
import { Drug } from "@/modules/settings/types/drug.type";
import { ContactBookmark } from "@/modules/contacts/types/contact-bookmark.type";
import ContactMenu from "@/modules/contacts/components/ContactMenu.vue";
import EmailContactSearch from "@/modules/contacts/components/EmailContactSearch.vue";

@Component({
  components: {
    ContactMenu,
    EmailContactSearch
  }
})
export default class PatientXrayReferenceFormDialog extends mixins(SmileMixin) {
  @Prop({ required: true }) public patient: Patient;
  @Getter("auth/therapistUsers") therapistUsers: User[];
  @Getter("auth/drugs") drugList: Drug[];
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isEmailEnabled") isEmailEnabled: boolean;
  @Getter("auth/isWhatsappBotEnabled") isWhatsappBotEnabled: boolean;

  public notes = "";
  public date = "";
  public phone = "";
  public email = "";
  public emailCc = "";
  public emailCc2 = "";
  public isMarkedSendWaToPatient = false;
  public isMarkedSendEmailToPatient = false;
  public isMarkedSendEmailCcPatient = false;
  public isMarkedSendEmailCc2Patient = false;
  public type1Checked = false;
  public type11Checked = false;
  public type2Checked = false;
  public type22Checked = false;
  public type21Checked = false;
  public type3Checked = false;
  public type4Checked = false;
  public type4Notes = "";
  public type5Checked = false;
  public type5Notes = "";
  public type6Checked = false;
  public type6Notes = "";
  public showErrors = false;
  public errors: any = false;
  public isSendLoading = false;
  public userId: number = null;
  public xrayContactId: number = null;
  public showFreeDrugName = false;

  public mounted() {
    this.phone = this.patient.phone;
    this.email = this.patient.email;
    this.notes = this.$store.getters["auth/xrayReferenceDefaultNote"];
    this.date = moment().format("DD-MM-YYYY");
    if (this.patient.attributes && this.patient.attributes.referred_by_contact_ids && this.patient.attributes.referred_by_contact_ids.length) {
      const contactIdWithEmail = this.patient.attributes.referred_by_contact_ids.find(contactId => this.contactById(contactId).email);
      if (contactIdWithEmail) {
        this.emailCc = this.contactById(contactIdWithEmail).email;
      }
    }
    if (this.$store.getters["auth/isTherapist"]) {
      this.userId = this.$store.getters["auth/userId"];
    } else if (this.therapistUsers.length === 1) {
      this.userId = this.therapistUsers[0].id;
    } else {
      // Default 
      this.userId = this.therapistUsers[0].id;
    }

    if (this.drugList.length === 0) {
      this.showFreeDrugName = true;
    }
  }

  public emailCcContact(email: string) {
    this.emailCc = email;
    this.isMarkedSendEmailCcPatient = true;
  }

  public emailCc2Contact(email: string) {
    this.emailCc2 = email;
    this.isMarkedSendEmailCc2Patient = true;
  }

  public async closeDialog() {
    this.$emit("close");
  }

  public async submitForm() {
    this.errors = false;
    this.showErrors = true;

    const isSendingLetterWarn = (this.isEmailEnabled || this.isWhatsappBotEnabled) && !this.isMarkedSendWaToPatient && !this.isMarkedSendEmailToPatient && !this.isMarkedSendEmailCcPatient;
    const res = await this.$confirm(
      this.$t(isSendingLetterWarn ? "create_letter_confirm_message" : ((this.isEmailEnabled || this.isWhatsappBotEnabled) ? "send_letter_confirm_message" : "create_letter_file_confirm_message")).toString(),
      {
        color: isSendingLetterWarn ? "pink lighten-3" : undefined,
        icon: "",
        title: isSendingLetterWarn ? this.$t("create_letter_confirm_title").toString() : undefined,
        buttonTrueColor: "cyan darken-2",
        buttonTrueText: this.$t("send_letter_ok_btn").toString(),
        buttonFalseText: this.$t("send_letter_cancel_btn").toString(),
      });

    if (!res) {
      return;
    }

    const subject = "צילומי שיניים: " + this.patient.first_name + " " + this.patient.last_name + (this.patient.id_number ? " - " + this.patient.id_number : "");
    try {
      this.isSendLoading = true;
      const patientFile = await PatientLetterApi.createDynamicLetter("xray_reference",
        subject,
        this.selectedXrayContact ? this.selectedXrayContact.name : "מכון צילום",
        this.patient.id,
        {
          date: this.date,
          userName: this.userName(this.userId),
          notes: this.notes,
          xrayContactId: this.xrayContactId,
          type1Checked: this.type1Checked,
          type11Checked: this.type11Checked,
          type2Checked: this.type2Checked,
          type22Checked: this.type22Checked,
          type21Checked: this.type21Checked,
          type3Checked: this.type3Checked,
          type4Checked: this.type4Checked,
          type4Notes: this.type4Notes,
          type5Checked: this.type5Checked,
          type5Notes: this.type5Notes,
          type6Checked: this.type6Checked,
          type6Notes: this.type6Notes,
          contactDetails: this.contactDetails,
          contactDetailsUrl: this.selectedXrayContact && this.selectedXrayContact.attributes && this.selectedXrayContact.attributes.detailsUrl ? this.selectedXrayContact.attributes.detailsUrl : null,
          contactLogoUrl: this.selectedXrayContact && this.selectedXrayContact.attributes && this.selectedXrayContact.attributes.logoUrl ? this.selectedXrayContact.attributes.logoUrl : null,
        },
        this.isMarkedSendEmailToPatient ? this.email : "",
        this.isMarkedSendEmailCcPatient ? this.emailCc : "",
        this.isMarkedSendEmailCc2Patient ? this.emailCc2 : "",
        this.isMarkedSendWaToPatient ? this.phone : "");
      this.$toastr.s(this.$t("xray_reference_created"));
      this.$emit("refreshPatientFiles", patientFile);
      this.closeDialog();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors) {
        this.errors = err.response.data.errors;
      } else {
        this.errors = this.$t("temporary_error");
      }
    } finally {
      this.isSendLoading = false;
      this.$emit("refreshPatientFiles");
    }
  }

  public onPhoneInputBlur() {
    if (this.phone) {
      this.isMarkedSendWaToPatient = true;
    }
  }

  public onEmailInputBlur() {
    if (this.email) {
      this.isMarkedSendEmailToPatient = true;
    }
  }

  public onEmailCcInputBlur() {
    if (this.emailCc) {
      this.isMarkedSendEmailCcPatient = true;
    }
  }

  public onEmailCc2InputBlur() {
    if (this.emailCc2) {
      this.isMarkedSendEmailCc2Patient = true;
    }
  }

  get selectedXrayContact() {
    if (!this.xrayContactId) {
      return null;
    }

    const contactBookmark = (this.$store.getters["auth/contacts"] as ContactBookmark[])
      .find(({ contacts }) => (contacts.find(({ id }) => (id === this.xrayContactId))));

    if (!contactBookmark) {
      return null;
    }

    return contactBookmark.contacts
      .find(({ id }) => (id === this.xrayContactId));
  }

  get contactDetails() {
    if (!this.xrayContactId) {
      return null;
    }

    const contact = this.selectedXrayContact;

    let notes = "";

    if (contact.phone) {
      notes += this.$t("xray_phone") + ": " + contact.phone + "\n";
    }

    if (contact.email) {
      notes += this.$t("xray_email") + ": " + contact.email + "\n";
    }

    notes += contact.notes;

    return notes.trim();
  }

  get xrayContacts() {
    const contactBookmarks = (this.$store.getters["auth/contacts"] as ContactBookmark[])
      .filter(({ display_type }) => (display_type && display_type.includes("xrayReference")));

    return contactBookmarks.map(({ contacts }) => (contacts))
      .flat();
  }

}
