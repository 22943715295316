import { render, staticRenderFns } from "./SharePatientFileEmailDialog.vue?vue&type=template&id=5b1f6d42&"
import script from "./SharePatientFileEmailDialog.vue?vue&type=script&lang=ts&"
export * from "./SharePatientFileEmailDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../../locales/share-patient-file-email-dialog.locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fpatient-file%2Fcomponents%2Fpatient-file%2FSharePatientFileEmailDialog.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports